body {
    background-color: #fff
        /* rgba(245, 245, 245, 0.568) */
    ;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.line {
    margin: 3vw 0;
    width: 100%;
    height: 1px;
    border-radius: 50%;
    background: rgba(145, 139, 139, 0.685);
}

.courseContainer {
    width: 90%;
    margin: 5vw auto;
    padding: 8px 10px;
    border-left: 5px solid;
    border-radius: 2vw;
    background: #fff;
    animation: fade-in-up .8s ease-in-out;
    color: dimgray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 8px -4px;
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    70% {
        transform: translateY(-3px);
    }

    100% {
        opacity: 1;
    }
}

.courseTypeContainer {
    color: #1E90FF;
}

.courseContentContainer {
    display: flex;
    justify-content: space-between;
}
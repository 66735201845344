.course-status-container {
  width: 90%;
  margin: 5vw auto;
  padding: 8px 10px;
  border: 1px dashed rgba(145, 139, 139, 0.685);
  border-radius: 2vw;
  background: #fff;
  color: dimgray;
  font-size: 10px;
  line-height: 2;
  animation: fade-in .5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}